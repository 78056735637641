@import "~react-image-gallery/styles/css/image-gallery.css";
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");

/* Color */
.text-1 {
  color: #493f38;
}

.text-2 {
  color: #c26e03;
}

.text-3,
.text-3:hover {
  color: #36280b;
}

.text-2:hover {
  color: #c26e03;
}

.bg-1 {
  background-color: #1a1309;
}

.bg-1:hover {
  background-color: #36280b;
}

.bg-2 {
  background-color: #36280b;
}
.bg-3 {
  background-color: #c26e03;
}

/* used commen classes */
.cursor-pointer {
  cursor: pointer;
}

.img-border {
  border-radius: 0 0 30px 0;
}

/* Tabs UI */

.custom-tabs > .nav-tabs .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.9) !important;
  background-color: transparent;
  border: none;
  padding: 15px;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(0, 0, 0);
}

.custom-tabs > .nav-tabs .nav-link.active {
  border-bottom: 3px solid orange;
  color: #2d281a;
}

/* End Tabs */

ul li {
  padding: 5px 0;
}

/*  */

/* bg-img position */
.bg-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.title-font-size {
  font-size: 16px;
}

.btn-read-more {
  background-color: #262626;
  color: #d99a04;
  border-radius: 0 0 30px 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 8px;
  font-size: 12px;
}

.btn-read-more:hover {
  color: #d99a04;
}

.team-heading {
  color: #493f38;
  font-weight: 900;
}

/* Mobile */
@media (max-width: 991.98px) {
  .col-100 {
    flex: auto !important;
    margin-bottom: 80px !important;
  }

  .custom-tabs > .nav-tabs .nav-link {
    padding: 5px !important;
    font-size: 12px !important;
  }

  .logo-width {
    width: 50px !important;
  }

  .navbar-brand {
    padding-top: 0;
  }

  .img-width-sm {
    max-width: 200px;
  }

  .heading-font {
    font-size: 1.2rem;
  }

  .navbar-light .navbar-nav .nav-link {
    color: rgb(255 255 255 / 90%);
  }

  .country-logo {
    top: -85px;
  }
  .sm-fs-12 {
    font-size: 12px !important;
  }
  .sm-icon-size {
    width: 30px !important;
    height: 30px !important;
  }
  .timeline-icon-width {
    width: 20px;
  }
  .btn-align {
    float: right;
  }
}

/* Desktop */

@media (min-width: 992px) {
  .link-fs {
    font-size: 16px !important;
  }

  .custom-arrow > div > .imYUvI {
    position: unset !important;
  }

  .custom-arrow > div > .imYUvI > .dKyEzi {
    left: 10px !important;
  }

  .custom-arrow > div > .imYUvI > .kFQKXd {
    right: unset !important;
    left: 50px !important;
  }

  .arrow-padding > div > .imYUvI {
    padding: 0 30px;
  }

  .counter-font-size {
    font-size: 64px !important;
  }

  .team-heading {
    font-size: 50px !important;
  }

  .custom-tabs > .nav-tabs {
    margin: 0 5rem;
  }

  .mt-large {
    margin-top: 5rem !important;
  }

  .country-logo {
    top: -25px;
  }
  .height-lg-90 {
    height: 90% !important;
  }

  .google-map {
    height: 80vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }
  .col-lg-20 {
    max-width: 20% !important;
  }
  .timeline-icon-width {
    width: 40px;
  }

}

.bg-white-hover:hover {
  background-color: #ffffff !important;
}

.active-item:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.one-line {
  overflow: hidden;
  display: block;
  line-clamp: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* Start Carousel  */
.imghvr-slide-up:hover div > div {
  opacity: 0;
}

.imghvr-slide-up {
  transition: transform 0.2s;
}

.imghvr-slide-up:hover {
  background: rgba(0, 0, 0, 0.5);
  transform: scale(1.01);
}

.commen-text {
  text-align: justify;
  color: #444;
  font-size: 16px;
}

.cut-text {
  overflow: hidden;
  display: block;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cut-text-about {
  overflow: hidden;
  display: block;
  line-clamp: 6;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.cut-text-products-services {
  overflow: hidden;
  display: block;
  line-clamp: 6;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.cut-text-awards {
  overflow: hidden;
  display: block;
  line-clamp: 5;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.cut-text-about {
  overflow: hidden;
  display: block;
  line-clamp: 4;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* End Carousel  */

/* Header */
.activeLink {
  color: #d99a04 !important;
}

.contact-us-gradient {
  background-image: linear-gradient(to right, #08ad00, #087e00);
}

.nav-link:hover {
  color: unset;
}

/* end header */
/* scrollbar css */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: orange;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(225, 150, 12);
}

.sidenav {
  width: 0;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
}

.header {
  position: fixed;
  z-index: 1024;
  top: 0;
  width: 100%;
  transition: all 0.35s ease;
}

.opacity-0 {
  opacity: 0;
  transition: opacity 0.35s ease;
}

.more-info {
  color: rgb(225, 150, 12);
}

.more-info:hover {
  color: rgb(225, 150, 12);
}

.img-zoom {
  transition: transform 0.2s;
}

.img-zoom:hover {
  transform: scale(1.5);
}

/* Background Video */

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* z-index: -1; */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.on-hover {
  transition: transform 0.2s;
  cursor: pointer;
}

.on-hover:hover {
  transform: scale(1.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.products-title {
  font-size: 1em;
  font-weight: 700;
  text-transform: none;
}

/*Vertical Sliding*/
.slidingVertical {
  display: inline;
}

.slidingVertical {
  padding: 0 10rem 8px 1rem;
  text-align: center;
}

.slidingVertical span {
  animation: top 7.5s linear infinite 0s;
  -ms-animation: top 7.5s linear infinite 0s;
  -webkit-animation: top 7.5s linear infinite 0s;
  opacity: 0;
  font-weight: 900 !important;
  overflow: hidden;
  position: absolute;
}

.slidingVertical span:nth-child(2) {
  animation-delay: 2.5s;
  -ms-animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
}

.slidingVertical span:nth-child(3) {
  animation-delay: 5s;
  -ms-animation-delay: 5s;
  -webkit-animation-delay: 5s;
}

.light-text-color {
  color: #444444;
}

/*top Animation*/
@keyframes top {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
    -webkit-transform: translateY(-25px);
  }

  10% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  25% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  30% {
    opacity: 0;
    -webkit-transform: translateY(25px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

/* About Us */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Dropdown Button */

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1a1309;
  min-width: 160px;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content .sub-menu {
  color: #ddd;
  padding: 8px 15px;
  text-decoration: none;
  display: block;
}

.dropdown-content .sub-menu:hover {
  background-color: #f1f1f1;
  color: #1a1309;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* top to page */
.go-to-top {
  position: fixed;
  bottom: 0;
  right: 5px;
  z-index: 99;
  font-size: 18px;
  color: white;
  background-color: #707070;
  cursor: pointer;
  padding: 0 15px;
  border-radius: 10px 10px 0 0;
}

.go-to-top:hover {
  background-color: #fca900;
  color: white;
}

/* TimeLine */
.vertical-timeline::before {
  background: #707070 !important;
  width: 6px !important;
}

.vertical-timeline-element-date {
  color: #36280b;
}

.footer-ribbon {
  position: absolute;
  top: 0;
  margin: -16px 0 0;
  padding: 10px 20px 6px;
  color: #fff;
  z-index: 101;
  background-color: #fca900;
  font-style: italic;
}

.footer-ribbon:before {
  content: "";
  display: block;
  height: 0;
  position: absolute;
  top: 0;
  width: 7px;
  right: 100%;
  border-right: 10px solid #646464;
  border-top: 16px solid transparent;
}

/* Map */

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 30vh;
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.3em;
}

svg.image-gallery-svg {
  height: 20px !important;
  width: unset !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.on-hover:hover {
  text-decoration: underline !important;
}
.zoom {
  transition: transform 0.2s;
  cursor: pointer;
}
.zoom:hover {
  transform: scale(1.02);
}
/* pagination */
.page-item.active .page-link {
  box-shadow: none !important;
  background-color: #c26e03 !important;
  border-color: #c26e03 !important;
  color: #fff !important;
}
.page-item .page-link {
  border-color: #c26e03 !important;
  color: #333 !important;
}
.vertical-timeline-element-date {
  font-size: 20px !important;
  font-weight: bolder !important;
}
/* WhatsApp Button */
.floating-whatsapp-btn > div {
  z-index: 1000 !important;
}
