@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

html {
    scroll-behavior: smooth !important;
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
}